import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useKeyPress } from "~hooks";
import { Button, Grid, Link, SVG } from "~components";
import { breakpoint } from "~utils/css";

// const SaleDataJSX = lazy(() =>
//   import(`../../components/Product/ProductChart/index`)
// );
// const ProductCertificate = lazy(() =>
//   import(`../../components/Product/ProductCertificate/index`)
// );

/** ============================================================================
 * @css
 */
const Container = styled.div`
  width: 100%;
  position: relative;
  display: block;
  padding: 2.5rem 0;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};

  ${breakpoint(`large-tablet`)} {
    padding: 3.75rem 0;
  }
`;

//

const Sidebar = styled.nav`
  display: none;

  ${breakpoint(`large-tablet`)} {
    display: block;

    grid-column: span 2 / span 2;
    height: auto;
    position: sticky;
    top: 7rem;
    border-radius: 6px;
    overflow: hidden;
    background: var(--color-black);
    color: var(--color-white);
  }

  ${breakpoint(`large-desktop`)} {
    grid-column-start: 3;
  }
`;
const SidebarLink = styled.button`
  transition: 0.3s var(--cubic-easing) color;

  color: var(--color-${({ active }) => `${active ? `white` : `mono-40`}`});

  width: 100%;
  position: relative;
  padding: 1rem;
  text-align: left;
`;

//

const Panes = styled.div`
  position: relative;
  display: none;

  ${breakpoint(`large-tablet`)} {
    grid-column: span 8 / span 8;
    grid-column-start: 4;
    display: block;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: span 5 / span 5;
    grid-column-start: 6;
  }
`;
const Pane = styled.div`
  animation: var(--animation-appear-right);

  width: 100%;
  min-height: 700px;
  position: relative;
`;
const TouchExpanders = styled.ul`
  grid-column: 1 / -1;

  ${breakpoint(`large-tablet`)} {
    display: none;
  }
`;
const TouchExpanderButton = styled.button`
  width: 100%;
  position: relative;
  z-index: 10;
  display: block;
  padding: 1.5rem 0;
  border-top: 1px solid var(--color-mono-20);
  text-align: left;
`;
const TouchExpanderButtonInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TouchExpanderContent = styled.div`
  margin-bottom: 3rem;
`;

//

const TableHeading = styled.h3`
  margin-bottom: 2.5rem;
`;
const Table = styled.ul`
  width: 100%;
  position: relative;

  ${breakpoint(`large-tablet`)} {
    width: 75%;
    margin-bottom: 5rem;
  }
`;
const TableRow = styled.li`
  width: 100%;
  position: relative;
  display: block;
  padding: 8px 0;

  ${breakpoint(`large-tablet`)} {
    display: flex;
    border-bottom: 1px solid var(--color-mono-20);
  }
`;
const TableKey = styled.h5`
  width: 100%;
  position: relative;
  padding-right: 1rem;
  margin-bottom: 4px;
  color: var(--color-mono-40);

  ${breakpoint(`large-tablet`)} {
    width: 33.3333%;
  }
`;
const TableValue = styled.p`
  width: 100%;
  position: relative;

  ${breakpoint(`large-tablet`)} {
    width: 66.6666%;
  }
`;

//

const OVERLAY_CSS = `
  transition: 0.3s var(--cubic-easing) opacity,
    0.3s var(--cubic-easing) transform;

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;
const OVERLAY_CLOSE_CSS = `
  width: 40px;
  height: 40px;
  top: 16px;
  right: 24px;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  border-radius: 100%;
  background: var(--color-mono-10);
  border: 1px solid var(--color-mono-20);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  ${breakpoint(`large-tablet`)} {
    top: 42px;
    right: 56px;
  }
`;
const OVERLAY_CLOSE_BUTTON_CSS = `
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
`;
const CertificateOverlay = styled.div`
  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};
  transform: scale(${({ active }) => `${active ? 1 : 1.025}`});

  ${OVERLAY_CSS};
`;
const CertificateOverlayClose = styled.div`
  ${OVERLAY_CLOSE_CSS};
`;
const CertificateCloseButton = styled.button`
  ${OVERLAY_CLOSE_BUTTON_CSS}
`;
const Certificate = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

const SaleDataOverlay = styled.div`
  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};
  transform: scale(${({ active }) => `${active ? 1 : 1.025}`});

  ${breakpoint(`large-tablet`)} {
    display: none;
  }

  ${OVERLAY_CSS};
  display: block;
`;
const SaleDataOverlayClose = styled.div`
  ${OVERLAY_CLOSE_CSS};
`;
const SaleDataCloseButton = styled.button`
  ${OVERLAY_CLOSE_BUTTON_CSS};
`;
const SaleDataOverlayContent = styled.div`
  grid-column: 1 / -1;
  height: 100%;
  position: relative;
  overflow: scroll;
  padding-top: 4.75rem;

  .sales-table {
    width: 550px;
  }
`;

/** ============================================================================
 * @component
 */
const ProductDetail = ({ enrichedProduct, data: { backgroundColour, fontColour } }) => {
  // ---------------------------------------------------------------------------
  // imports

  const escKeyPressed = useKeyPress(`Escape`);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const containerRef = useRef();

  const [activePane, setActivePane] = useState(0);
  const [certificateActive, setCertificateActive] = useState(false);
  const [saleDataActive, setSaleDataActive] = useState(false);
  const [lucidProduct, setLucidProduct] = useState(null);
  const [guaranteeCertificateLabel, setGuaranteeCertificateLabel] = useState(`View Certificate`);
  const [guaranteeCertificateUrl] = useState(``);
  const [expandedIndex, setExpandedIndex] = useState(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setCertificateActive(false);
  }, [escKeyPressed]);

  useEffect(() => {
    if (lucidProduct || !enrichedProduct) {
      return;
    }
    setLucidProduct(enrichedProduct);

    if (!enrichedProduct?.product?.guaranteeCertificate) {
      return;
    }

    // const { guaranteeCertificate } = enrichedProduct.product;

    if (enrichedProduct?.product?.guaranteeCertificateIpfsUrl) {
      setGuaranteeCertificateLabel(`View Certificate On Ipfs`);

      // setGuaranteeCertificateUrl(
      //   enrichedProduct.product.guaranteeCertificateIpfsUrl
      // );
    } else {
      // setGuaranteeCertificateUrl(guaranteeCertificate?.publicUrl);
    }
  }, [enrichedProduct?.productGuid]);

  // ---------------------------------------------------------------------------
  // render

  const basicInformationJSX = (
    <Table>
      {lucidProduct?.product?.attributes?.[0] &&
        lucidProduct.product.attributes.map(({ label, value }) => (
          <TableRow key={`product-attribute-${label.toLowerCase()}`}>
            <TableKey className="b2">{label}</TableKey>
            <TableValue
              className="b2"
              css={css`
                overflow-wrap: break-word;
              `}
            >
              {value}
            </TableValue>
          </TableRow>
        ))}
    </Table>
  );

  const blockchainDataJSX = (
    <Table>
      <TableRow>
        <TableKey className="b2">NFT ID</TableKey>
        <TableValue className="b2">{lucidProduct?.nftData?.tokenId || ``}</TableValue>
      </TableRow>

      {lucidProduct?.nftData?.nftCollectionInfo?.collectionAddress && (
        <TableRow>
          <TableKey className="b2">NFT Collection</TableKey>
          <TableValue className="b2">
            <Link
              css={css`
                &:hover {
                  text-decoration: underline;
                  color: var(--color-primary-orange-100);
                }
              `}
              to={lucidProduct.nftData.contractExplorerUrl}
            >
              {lucidProduct.nftData.nftCollectionInfo.collectionAddress}
            </Link>
          </TableValue>
        </TableRow>
      )}
    </Table>
  );

  const certificateButton = (
    <Button
      onClick={() => setCertificateActive(true)}
      css={css`
        width: 100%;
        padding: 0 14px 0;

        ${breakpoint(`large-tablet`)} {
          width: auto;
          margin-left: 2.5rem;
        }
      `}
      square
      type="button"
      color="primary-orange-100"
      transparent
    >
      <SVG
        svg="paperclip"
        css={css`
          width: 14px;
          margin-right: 8px;
        `}
      />
      <span className="button-text">{guaranteeCertificateLabel}</span>
    </Button>
  );

  return (
    <Container ref={containerRef} className="container" background={backgroundColour?.hex} color={fontColour?.hex}>
      {/* {lucidProduct?.product?.guaranteeCertificate?.publicUrl && ( */}
      <CertificateOverlay active={certificateActive}>
        <CertificateOverlayClose>
          <CertificateCloseButton type="button" onClick={() => setCertificateActive(false)}>
            <SVG
              svg="cross"
              css={css`
                width: 1rem;
                pointer-events: none;
              `}
            />
          </CertificateCloseButton>
        </CertificateOverlayClose>

        {/* <Suspense fallback={<div>Loading...</div>}> */}
        {/* <ProductCertificate guaranteeCertificateUrl={guaranteeCertificateUrl} /> */}
        {/* </Suspense> */}
      </CertificateOverlay>
      {/* )} */}

      <SaleDataOverlay active={saleDataActive}>
        <SaleDataOverlayClose>
          <SaleDataCloseButton type="button" onClick={() => setSaleDataActive(false)}>
            <SVG
              svg="cross"
              css={css`
                width: 1rem;
                pointer-events: none;
              `}
            />
          </SaleDataCloseButton>
        </SaleDataOverlayClose>

        <Grid
          css={css`
            height: 100%;
          `}
        >
          <SaleDataOverlayContent>
            {/* <Suspense fallback={<div>Loading...</div>}> */}
            {/* <SaleDataJSX /> */}
            {/* </Suspense> */}
          </SaleDataOverlayContent>
        </Grid>
      </SaleDataOverlay>

      <Grid
        css={css`
          align-items: start;
        `}
      >
        <Sidebar>
          <SidebarLink active={activePane === 0} onClick={() => setActivePane(0)}>
            Basic Information
          </SidebarLink>
          <SidebarLink active={activePane === 1} onClick={() => setActivePane(1)}>
            Blockchain Data
          </SidebarLink>
          <SidebarLink active={activePane === 2} onClick={() => setActivePane(2)}>
            Historical Sales Data
          </SidebarLink>

          {lucidProduct?.product?.guaranteeCertificate?.publicUrl && (
            <SidebarLink active={activePane === 3} onClick={() => setActivePane(3)}>
              Authenticity Certificate
            </SidebarLink>
          )}
        </Sidebar>

        {/* // */}

        {/* // todo: better error boundaries? // */}
        {/* <Suspense> */}
        <Panes>
          {activePane === 0 && lucidProduct?.product?.attributes?.[0] && (
            <Pane>
              <TableHeading className="h4">Basic Information</TableHeading>
              {basicInformationJSX}
            </Pane>
          )}

          {activePane === 1 && lucidProduct?.nftData && (
            <Pane>
              <TableHeading className="h4">Blockchain Data</TableHeading>
              {blockchainDataJSX}
            </Pane>
          )}

          {activePane === 2 && (
            <Pane>
              <TableHeading className="h4">Historical Sales Data</TableHeading>

              {/* <Suspense fallback={<div>Loading...</div>}> */}
              {/* <SaleDataJSX /> */}
              {/* </Suspense> */}
            </Pane>
          )}

          {activePane === 3 && lucidProduct?.product?.guaranteeCertificate?.publicUrl && (
            <Pane>
              <Certificate>
                <TableHeading className="h4">Authenticity Certificate</TableHeading>

                {certificateButton}
              </Certificate>
            </Pane>
          )}
        </Panes>
        {/* </Suspense> */}

        <TouchExpanders>
          <li>
            <TouchExpanderButton active={expandedIndex === 0} onClick={() => setExpandedIndex(expandedIndex === 0 ? null : 0)}>
              <TouchExpanderButtonInner>
                <span className="h4">Basic Information</span>

                <SVG
                  svg="chevronDown"
                  css={css`
                    width: 14px;
                  `}
                />
              </TouchExpanderButtonInner>
            </TouchExpanderButton>

            {expandedIndex === 0 && <TouchExpanderContent>{basicInformationJSX}</TouchExpanderContent>}
          </li>

          <li>
            <TouchExpanderButton active={expandedIndex === 1} onClick={() => setExpandedIndex(expandedIndex === 1 ? null : 1)}>
              <TouchExpanderButtonInner>
                <span className="h4">Blockchain Data</span>

                <SVG
                  svg="chevronDown"
                  css={css`
                    width: 14px;
                  `}
                />
              </TouchExpanderButtonInner>
            </TouchExpanderButton>

            {expandedIndex === 1 && <TouchExpanderContent>{blockchainDataJSX}</TouchExpanderContent>}
          </li>

          <li>
            <TouchExpanderButton active={expandedIndex === 2} onClick={() => setExpandedIndex(expandedIndex === 2 ? null : 2)}>
              <TouchExpanderButtonInner>
                <span className="h4">Historical Sales Data</span>

                <SVG
                  svg="chevronDown"
                  css={css`
                    width: 14px;
                  `}
                />
              </TouchExpanderButtonInner>
            </TouchExpanderButton>

            {expandedIndex === 2 && (
              <TouchExpanderContent>
                <Button color="primary-orange-100" onClick={() => setSaleDataActive(true)} full large square>
                  <SVG
                    svg="database"
                    css={css`
                      width: 14px;
                      margin-right: 8px;
                    `}
                  />

                  <span className="button-text">See Sales Data</span>
                </Button>
              </TouchExpanderContent>
            )}
          </li>

          {guaranteeCertificateUrl && (
            <li>
              <TouchExpanderButton active={expandedIndex === 3} onClick={() => setExpandedIndex(expandedIndex === 3 ? null : 3)}>
                <TouchExpanderButtonInner>
                  <span className="h4">Authenticity Certificate</span>

                  <SVG
                    svg="chevronDown"
                    css={css`
                      width: 14px;
                    `}
                  />
                </TouchExpanderButtonInner>
              </TouchExpanderButton>

              {expandedIndex === 3 && <TouchExpanderContent>{certificateButton}</TouchExpanderContent>}
            </li>
          )}
        </TouchExpanders>
      </Grid>
    </Container>
  );
};

export default ProductDetail;
